<template>
  <div class="all">
    
    <div v-if="ifshowthelogin" class="loginall">
      <div  class="login-container">
        <label class="yeshiname">微夜市</label><h1>平台管理系统</h1>
        <form @submit.prevent="login">
          <label for="username">用户名</label>
          <input type="text" class="firstinput" v-model="usename" @keyup.enter="login" required />

          <label for="password">密码</label>
          <input type="password" class="firstinput" v-model="password"  @keyup.enter="login" required />

          <div class="button1">
            <div class="button2">
              <button type="button" @click="login">登录</button>
            <button type="button" @click="register">注册</button>
              </div>
            
            <label style="color:red;margin-top:30px" v-if="offlogin">账号或密码输入错误,你还可以输入{{inputcount}}次</label>
          </div>
        </form>
      </div>
    </div>
    

    <router-view v-if="ifshowthereg"></router-view>
  </div>


</template>

<script>
// eslint-disable-next-line no-unused-vars
import { sha256 } from 'js-sha256'
// eslint-disable-next-line no-unused-vars
import axios from 'axios'
export default {
  
  data() {
    return {
      usename: '',
      password:'',
      pass_hash: '',
      ifshowthelogin:1,
      ifshowthereg:0,
      offlogin:0,
      inputcount:5,
    };
  },
  methods: {

    login() {
      var that = this
      var axios = require("axios").default;
      this.pass_hash = sha256(this.password)
var options = {
  method: 'POST',
  url: 'http://47.109.96.52:8080/Login/Manager',
  headers: {
    Accept: 'application/json',
    'content-type': 'multipart/form-data; boundary=---011000010111000001101001'
  },
  data: '-----011000010111000001101001\r\nContent-Disposition: form-data; name="usename"\r\n\r\n'+this.usename+'\r\n-----011000010111000001101001\r\nContent-Disposition: form-data; name="passHash"\r\n\r\n'+this.pass_hash+'\r\n-----011000010111000001101001--\r\n\r\n'
};



axios.request(options).then(function (response) {

  if(response.data.msg=='请求成功'){
    that.$router.push('/about')
    that.ifshowthereg=1
    that.ifshowthelogin=0
    let token=response.data.data.token
    localStorage.setItem('token', token)
    console.log(token)
    that.getwxtoken()
  }
  else{
    that.offlogin=1
    that.inputcount=that.inputcount-1
  }
}).catch(function (error) {
  console.error(error);
});
      
    },


    getwxtoken(){
            let token = localStorage.getItem('token');
            const options = {
            method: 'POST',
            url: 'http://47.109.96.52:8080/Manager/zhuanfa/token',
            params: {
              token: token
            },
            headers: {
              Accept: 'application/json',
              'content-type': 'application/x-www-form-urlencoded'
            },
            data: {
              grant_type: 'client_credential',
              appid: 'wx79d883d1c3018ae4',
              secret: 'c03cb0198ea051504b19789f4915af37'
            }
          };

          axios.request(options).then(function (response) {

            var jsonData = JSON.parse(response.data.msg);
            localStorage.setItem('accesstoken', jsonData.access_token)
            console.log(jsonData.access_token)
          }).catch(function (error) {
            console.error(error);
          });
    },


    register() {
      this.$router.push('/')
      console.log("abc")
      this.ifshowthelogin=0
      this.ifshowthereg=1
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;

}


.loginall{

  width: 100vw;
  height: 100vh;

  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

}

.login-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: white;
}
.button1{
  flex-wrap: wrap;
}
.button1 button{
  margin-left:40px;
  margin-right:40px;
  background-color: #3d367d; /* 默认按钮颜色 */
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width:80px;
}
.button1 button:hover {
  background-color: #2980b9; /* 悬浮时按钮颜色 */
}
h1 {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 8px;
}

input[type=text], select {
	width: 100%;
	padding: 12px 20px;
	margin: 8px 0;
	display: inline-block;
	border: 1px solid rgb(12, 12, 12);
	border-radius: 4px;
	box-sizing: border-box;
}
input[type=password], select {
	width: 100%;
	padding: 12px 20px;
	margin: 8px 0;
	display: inline-block;
	border: 1px solid rgb(12, 12, 12);
	border-radius: 4px;
	box-sizing: border-box;
}

input[type=submit] {
	width: 100%;
	background-color: #4CAF50;
	color: white;
	padding: 14px 20px;
	margin: 8px 0;
	border: none;
	border-radius:4px;
	cursor: pointer;
}

input[type=submit]:hover {
	background-color: #45a049;
}


button {
  background-color: #3498db;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.button2{
      flex-wrap: nowrap;
    }
.yeshiname{
  font-size: 40px;
  font-weight: bold;
  color: #4c9f47;
}
    

</style>

<style>
body{
  
  margin: 0;
}
</style>