
<template>
  <div class="register-container">
    <h2>注册页面</h2>
    <form @submit.prevent="register">
      <div class="form-group">
        <label for="account">账号</label>
        <input type="text" v-model="account" required />
      </div>

      <div class="form-group">
        <label for="password">密码</label>
        <input type="password" v-model="password" required />
      </div>

      <div class="form-group">
        <label for="phoneNumber">手机号</label>
        <input type="text" v-model="phoneNumber" required />
      </div>

      <div class="form-group">
        <label for="passId">通行ID</label>
        <input type="text" v-model="passId" required />
      </div>

      <button type="submit">注册</button>
    </form>
  </div>
</template>

<script>
import { sha256 } from 'js-sha256'
export default {
  data() {
    return {
      account: '',
      password: '',
      phoneNumber: '',
      passId: ''
    };
  },
  methods: {
    register() {
      const formData = {
        account: this.account,
        password: this.password,
        phoneNumber: this.phoneNumber,
        passId: this.passId
      };
      fetch('/api/login', {//修改这儿
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
     })
      .then(response => response.json())
      .then(data => {
        console.log('后端返回的数据:', data);
      })
      .catch(error => {
        console.error('发生错误:', error);
      });
      // 模拟提交至服务器的 API 请求
      console.log('模拟提交至服务器的数据:', formData);

      // 实际项目中，你需要使用 fetch 或其他适当的方式将 formData 提交至服务器的 API
    }
  }
};
</script>

<style scoped>
.register-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
}

h2 {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
  text-align: left;
}

label {
  display: block;
  margin-bottom: 8px;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

button {
  width: 100%;
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}
</style>
